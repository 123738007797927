import React, { useEffect, useState } from 'react';
import { fetchData } from '../../Helpers/FetchData';
import { navigator } from '../../Controllers/Navigator';
import {ComingSoon} from "./ComingSoon";

interface Project {
    id: number;
    title: string;
    preview: string;
    html: string;
}

interface ProjectListProps {
    url: string; // URL для запиту
    paddingTop?: number;
    page?: string;
}

export const ProjectList: React.FC<ProjectListProps> = ({ url, paddingTop, page }) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetchData(url, 'GET', {});
                if (response.result) {
                    setProjects(response.data);
                } else {
                    setError(true);
                }
            } catch (err) {
                console.error('Error fetching projects:', err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, [url]);

    const handleProjectClick = (project: Project) => {
        if (page === 'project'){
            navigator().navigate(`/project/${project.id}`, project);
            return;
        }
        if (page === 'blog'){
            navigator().navigate(`/blog/${project.id}`, project);
            return;
        }
    };

    if (loading) {
        return (
            <div className="loader-container">
                <span className="loader"></span>
            </div>
        );
    }

    if (error) {
        return null;
    }
    return (
        <div className="project-list" style={{ paddingTop: `${paddingTop}px` }}>
            {projects.length === 0 ? (
                <ComingSoon />
            ) : (
                projects.map((project) => (
                    <div
                        key={project.id}
                        className="project-card"
                        onClick={() => handleProjectClick(project)}
                    >
                        {project.preview && (
                            <img
                                src={project.preview}
                                alt={project.title}
                                className="project-image"
                            />
                        )}
                        <h3 className="project-title">{project.title}</h3>
                    </div>
                ))
            )}
        </div>
    );
};
