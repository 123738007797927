import { HomePageModel } from "./PageControllers/HomePageModel";
import { JoinedPage } from "./PageControllers/JoinedPage";
import { PiecesOfHistoryPage } from "./PageControllers/PiecesOfHistoryPage";
import { TargetOrientedPage } from "./PageControllers/TargetOrientedPage";
import { TemplateProjectsController } from "./PageControllers/TemplateProjectsController";
import {BeforeAfterPhotosController} from "./PageControllers/BeforeAfterPhotosController";
import {DetailProjectsController} from "./PageControllers/DetailProjectsController";
import {DetailBlogController} from "./PageControllers/DetailBlogController";

class PageController {

    private readonly _home: HomePageModel;
    private readonly _targetOriented: TargetOrientedPage;
    private readonly _piecesOfHistory: PiecesOfHistoryPage;
    private readonly _templateProjects: TemplateProjectsController;
    private readonly _beforeAfterPhotos: BeforeAfterPhotosController;
    private readonly _joined: JoinedPage;
    private readonly _details: DetailProjectsController;
    private readonly _blog: DetailBlogController;

    constructor(){
        this._home = new HomePageModel('home');
        this._targetOriented = new TargetOrientedPage('target-oriented');
        this._piecesOfHistory = new PiecesOfHistoryPage('pieces-of-history');
        this._templateProjects = new TemplateProjectsController('template-projects');
        this._beforeAfterPhotos = new BeforeAfterPhotosController('before-after-photos');
        this._joined = new JoinedPage('joined');
        this._details = new DetailProjectsController('project');
        this._blog = new DetailBlogController('blog');
    }

    get home(){
        return this._home;
    }

    get targetOriented(){
        return this._targetOriented
    }

    get piecesOfHistory(){
        return this._piecesOfHistory
    }
    get templateProjects(){
        return this._templateProjects
    }
    get beforeAfterPhotos(){
        return this._beforeAfterPhotos
    }

    get joined(){
        return this._joined
    }
    get details(){
        return this._details
    }
    get blog(){
        return this._blog
    }
}

export { PageController }
