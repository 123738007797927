import { photosource } from "../../Helpers/Feathure";
import { MainCarousel } from "../design/MainCarousel";
import { WowCarousel } from "../design/WowCarousel";
import { WowText } from "../design/WowText";
import { PageView } from "./PageView";
import {ReviewList} from "../design/ReviewList";


class TargetOriented extends PageView {

    componentDidMount(): void {
        super.componentDidMount()
    }

    render() {
        return <div className="target-oriented page">
            <MainCarousel
                photos={[photosource("3AHJEZNMJjzB"), photosource("jwtKcBZ2ikVI"), photosource("FN4ghdYKbZh4")]}
                description="Process of work"
                customStyle="details-photo"
                centeredTitle
            />
            <WowText
                title='Details makes perfect'
            >
                Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time.
                Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love
            </WowText>
            <WowCarousel photos={[photosource("pgwghG4EGYKq"),photosource("bN4tdgpkUxGe"), photosource("bj5q8CICqGqC"), photosource("13JeQXozI0HL")]}/>
            <WowText
                title='How we work'
            >
                Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time.
                Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems
            </WowText>
            <WowCarousel photos={[photosource("XlU1iJSRJvAV"), photosource("YLwRh8ZfBfDK"), photosource("mSDJlPMYsJn0"), photosource("GJM5JK1IiiEE")]}/>
            <ReviewList />
        </div>
    }

}

export { TargetOriented }
