import React, { useState, useEffect } from 'react';
import {fetchData} from "../../Helpers/FetchData";

interface HtmlRendererProps {
    url?: string; // URL для запиту на сервер
    htmlContent?: string; // HTML-контент як строка
    paddingTop?: number;
}

export const HtmlRendererComponent: React.FC<HtmlRendererProps> = ({ url, htmlContent, paddingTop }) => {
    const [content, setContent] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (url) {
            fetchHtml(url);
        } else if (htmlContent) {
            setContent(htmlContent);
            setLoading(false);
        } else {
            setError(true);
            setLoading(false);
        }
    }, [url]);

    const fetchHtml = async (url: string) => {
        try {
            const response = await fetchData(url, 'GET', {});
            if (!response.result) setError(true);

            if (response.data) {
                const htmlList = response.data.html; // Використання map
                setContent(htmlList)
            } else {
                setError(true);
            }
        } catch (err) {
            console.error(err);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="loader-container">
                <span className="loader"></span>
            </div>
        )
    }

    if (error) {
        return null
    }
    return <div style={{paddingTop: paddingTop || 0 }} className="html-content" dangerouslySetInnerHTML={{ __html: content || '' }} />;
};
