import {ICONS} from "../../Constants/icons";

export const ContactInfo = () => (
    <div className="contact-info">
        <div className="footer-item-box">
            <a href="mailto:chobalrestoration@gmail.com" className={'td-none'}>chobalrestoration@gmail.com</a>
        </div>
        <div className="footer-item-box">
            <a href="tel:+420776227750" className={'td-none'}>+420 77 622 7750</a>
        </div>
        <div className="footer-item-box">Olexandr Chobal</div>
        <div className="social-links">
            <a href="https://www.instagram.com/chobal_restoration?igshid=YmMyMTA2M2Y%3D" target="_blank" className="social-icon" style={{marginRight: '25px'}}>
                <img src={ICONS.instagram} alt="Instagram" className={'wh28'} />
            </a>
            {/*<a href="https://www.facebook.com" target="_blank" className="social-icon">*/}
            {/*    <img src={ICONS.facebook} alt="Facebook" className={'wh28'} />*/}
            {/*</a>*/}
        </div>
    </div>
);
