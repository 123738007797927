import { photosource } from "../../Helpers/Feathure";
import { MainCarousel } from "../design/MainCarousel";
import { WowCarousel } from "../design/WowCarousel";
import { WowText } from "../design/WowText";
import { PageView } from "./PageView";
import {ReviewList} from "../design/ReviewList";


class PiecesOfHistory extends PageView {

    componentDidMount(): void {
        super.componentDidMount()
    }
    render(){
        return <div className="pieces-of-history page">
            <MainCarousel
                photos={[photosource("tetxRpAu2jDS"), photosource("Wk9k6KojM5sE"), photosource('YtKIwv8nfLk1')]}
                description="Pieces of history"
                centeredTitle
            />
            <WowText
                title='About us'
            >
                We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars.
                Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars,
                driven extensively by the most demanding collectors.
            </WowText>
            <WowCarousel photos={[photosource("xNXWulhoHCLp"),photosource("EfuUR64Atj5x"),photosource("aCxgN5FEjnA3")]}/>
            <WowText
                title='Where we are'
            >
                Our restorations are exhibited at various European competitions, such as Classic Expo and others.
                We direct our ambitions to realize the dreams of many connoisseurs of retro cars.
                Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations
            </WowText>
            <WowCarousel photos={[photosource("NJr1lldHSN1x"), photosource("PThZP2Ge2Z74"), photosource("2O3qaLNc6JWK"), photosource("CJ8utIRQ5d0t")]}/>
            <ReviewList />
        </div>
    }

}

export { PiecesOfHistory }
