import React from "react";
import {LangItem} from "../core/Helpers/LangItem";

export type ReviewItemProps = {
    id: number;
    name: string;
    avatar: string;
    date: string;
    rating: number;
    content: string;
};

class ReviewItem extends React.Component<ReviewItemProps> {
    constructor(props: ReviewItemProps) {
        super(props);
    }

    render() {
        const { name, avatar, date, rating, content } = this.props;
        return (
            <div className="review-item">
                <div>
                    <div className="review-header">
                        <img src={avatar} alt={''} className="review-avatar"/>
                        <div className="review-info">
                            <h3 className="review-name">{name}</h3>
                            <div className="review-stars">
                                {Array(5).fill(0).map((_, index) => (
                                    <span
                                        key={index}
                                        className={`star ${index < rating ? 'filled' : ''}`}
                                    >
                                    ★
                                </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <p className="review-content">{content}</p>
                </div>
                <small className="review-date"><LangItem for={date}/></small>
            </div>
        );
    }
}

export {ReviewItem};
