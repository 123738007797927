import React from "react";
import { controllers } from "../../Controllers/Controller";
import { LangItem } from "./Helpers/LangItem";
import { ViewItem } from "../Components/Classes/ViewItem";
import { FooterController } from "../../Controllers/PageControllers/FooterController";
import { FooterItemSuit } from "./Footer/FooterItemSuit";
import { FooterItem } from "./Footer/FooterItem";
import { navigator } from "../../Controllers/Navigator";
import { BlackLogoView } from "./Helpers/BlackLogoView";
import {ICONS} from "../../Constants/icons";
import {MapPhotoView} from "../Components/MapPhotoView";
import {ContactInfo} from "../Components/ContactInfo";
import {LanguagesMenu} from "./Menu/LanguagesMenu";



class FooterView extends ViewItem {

    get controller():FooterController{
        return this.props.controller
    }

    render(){
        const { socials } = this.controller
        return <div className="footer" onClick={controllers().media.mainContentClicked}>
        <div className="footer-box">
            <div className="footer-top">
             Chobal ⓒ {new Date().getFullYear()}
            </div>
            <div className="footer-body">
                <div className="footer-body-center-box fwrap">
                    <FooterItemSuit title="Navigation">
                        <div style={{minWidth: 150}}>
                            <FooterItem title="Home" onClick={()=>navigator().navigate('/')}/>
                            <FooterItem title="Pieces of history" onClick={()=>navigator().navigate('/piecesofhistory')}/>
                            <FooterItem title="Process of work" onClick={()=>navigator().navigate('/processing')}/>
                            <FooterItem title="Projects" onClick={()=>navigator().navigate('/projects')}/>
                            <FooterItem title="Blogs" onClick={()=>navigator().navigate('/blogs')}/>
                        </div>
                    </FooterItemSuit>
                    <FooterItemSuit title="Languages">
                         {/*@ts-ignore*/}
                        <LanguagesMenu useLangKey="fullName" />
                    </FooterItemSuit>
                    <FooterItemSuit title="Contact info">
                        <ContactInfo />
                    </FooterItemSuit>
                    <div className="footer-item-suit map-container" style={{marginTop: 15}}>
                        <MapPhotoView
                            imageUrl={ICONS.mapGif}
                            coordinates={{ lat: 49.5513713, lng: 18.2167104 }}
                            address="Mistecka 803, Frenstat pod Radhostem"
                        />
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                {socials && socials.length ? <div className="footer-bottom-title">
                    <LangItem for="Contacts"/>:
                </div>: null}
                <div className="footer-bottom-items">

                </div>

                <div className="media-footer-logo">
                    <div className="media-footer-logo-box jc-fe">
                        <div className="footer-logo">
                            <div className="footer-logo-box">
                                {/* <ImageView source={ICONS.mainlogoBlack} withoutLoader/> */}
                                <BlackLogoView/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
}

export { FooterView }
