import { UPDATE } from "../../../Actions/types";
import { controllers } from "../../../Controllers/Controller";
import { UpdateComponent } from "../../../Models/Components/UpdateComponent";
import { OneLanguageView } from "./OneLanguageView";

type LanguageItem = {
    name: string;
    fullName?: string;
};

type LanguagesMenuProps = {
    useLangKey?: keyof LanguageItem;
};
class LanguagesMenu extends UpdateComponent {

    constructor(props:LanguagesMenuProps){
        super(props)
        this.type = UPDATE.LANG
    }

    render() {
        const { lang, langs, setLanguage} = controllers().language
        // @ts-ignore
        const key: keyof LanguageItem = this.props.useLangKey || 'name';
        let className = key !== 'fullName' ?  "language-menu-box":  "language-menu-line";
        return <div className="language-menu">
            <div className={className}>
                {langs && langs.map(langItem => <OneLanguageView key={langItem.name} title={langItem[key] || langItem.name} selected={langItem.name === lang?.name} nameLang={langItem.name} select={setLanguage}/>)}
            </div>
        </div>
    }
}

export { LanguagesMenu }
