import React from "react";
import { ReviewItem, ReviewItemProps } from "../Components/ReviewItem";
import {LangItem} from "../core/Helpers/LangItem";
import {ICONS} from "../../Constants/icons";

type ReviewListProps = {
    dataList?: Array<ReviewItemProps>;
};

class ReviewList extends React.Component<ReviewListProps> {
    listReviews: Array<ReviewItemProps>;

    constructor(props: ReviewListProps) {
        super(props);
        this.listReviews = this.listReviews = [
            {
                id: 1,
                name: "Lukas Schneider",
                avatar: ICONS.avatar,
                date: "March 15",
                rating: 5,
                content:
                    "The restoration process was flawless. Every detail, from the bodywork to the interior, exceeded my expectations. The car looks and feels brand-new, and I couldn't be happier with the results.",
            },
            {
                id: 2,
                name: "Anna Müller",
                avatar: ICONS.avatar,
                date: "April 10",
                rating: 4,
                content:
                    "I had my Jaguar E-Type restored by Chobal. The interior looks amazing, and the car runs smoothly. However, there were some delays in the delivery process, but overall I'm very satisfied with the quality of work.",
            },
            {
                id: 3,
                name: "Maximilian Fischer",
                avatar: ICONS.avatar,
                date: "May 5",
                rating: 5,
                content:
                    "My Ferrari Dino 246 GT is now a showpiece. The attention to detail in the paint job and the engine restoration is incredible. I couldn’t be happier with the service provided by Chobal.",
            },
            {
                id: 4,
                name: "Sophie Wagner",
                avatar: ICONS.avatar,
                date: "June 20",
                rating: 5,
                content:
                    "I had high expectations for my BMW 2002 restoration. The bodywork is flawless, but I was a bit disappointed with some of the electrical detailing. However, the team was very supportive and fixed the issues quickly.",
            },
            {
                id: 5,
                name: "Leon Hoffmann",
                avatar: ICONS.avatar,
                date: "July 30",
                rating: 4,
                content:
                    "The restoration of my Mercedes-Benz SL was impressive. The interior looks brand-new, and the car drives like a dream. The team clearly cares about the small details, and it shows in the final result.",
            },
            {
                id: 6,
                name: "Mia Becker",
                avatar: ICONS.avatar,
                date: "August 12",
                rating: 5,
                content:
                    "Chobal’s team did an exceptional job restoring my Aston Martin DB5. The leather interior, the body, and even the smallest screws were restored to original specifications. I highly recommend them to any classic car enthusiast.",
            }
        ];
    }

    render() {
        const dataList = this.props.dataList || this.listReviews;
        return (
            <div className="review-list">
                <div className='reviews-title'>
                    <LangItem for={'REVIEWS'}/>
                </div>
                <div className="review-list-items">
                    {dataList.map((review) => (
                        <ReviewItem
                            key={review.id}
                            id={review.id}
                            name={review.name}
                            avatar={review.avatar}
                            date={review.date}
                            rating={review.rating}
                            content={review.content}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export {ReviewList};
