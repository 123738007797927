import { Model } from "../Core/Components/Model"
import { MenuContact } from "./Menu/MenuContact";
import { MenuHouper } from "./Menu/MenuHouper"
import { navigator } from "./Navigator";


class MenuController extends Model {
    private _isBlured: boolean
    private _isBluredLock: boolean
    private _leftContainer: MenuHouper;
    private _rightContainer: MenuHouper;
    private _contactForm: MenuContact;
    constructor(){
        super()
        this._isBlured = false
        this._isBluredLock = false
        this._contactForm = new MenuContact();
        this._leftContainer = new MenuHouper("left", [
            {title: "Home", click: ()=>this.navigate("/")},
            {title: "Pieces of history", click: ()=>this.navigate("/piecesofhistory")},
            {title: "Process of work", click: ()=>this.navigate("/processing")},
            {title: "Projects", click: ()=>this.navigate("/projects")},
            {title: "Blogs", click: ()=>this.navigate("/blogs")},
        ]);
        this._rightContainer = new MenuHouper("right");
    }

    navigate = (pageName: string) => {
        // console.error("Navigate", pageName)
        navigator().navigate(pageName)
        this.leftToggle()
    }

    leftToggle = () => {
        this._leftContainer.setVisible(!this._leftContainer.visible)
    }
    rightToggle = () => {
        this._rightContainer.setVisible(!this._rightContainer.visible)
    }
    rightHide = () => {
        this._rightContainer.setVisible(false)
    }

    get leftHouper(){
        return this._leftContainer
    }

    get rightHouper(){
        return this._rightContainer
    }

    get contactForm(){
        return this._contactForm
    }

    get isBlured(){
        return this._isBlured
    }

    setIsBlured(bool: boolean){
        if (this._isBluredLock){
            return;
        }
        if(this._isBlured === bool){
            return
        }
        this._isBlured = bool
        this.updateMe()
    }
    setIsBluredLock(bool: boolean){
        if(this._isBluredLock === bool){
            return
        }
        this._isBluredLock = bool
        this.updateMe()
    }


    page(str:string){
        // this.removeSelected()
        // const find = this.menuItems.find(p=>p.name===str)
        // if(find){
        //     find.setSelected(true)
        // }
    }

    removeSelected(){
        // const find = this._items.find(e=>e.selected)
        // if(find!==undefined){
        //     find.setSelected(false)
        // }
    }
}

export { MenuController }
