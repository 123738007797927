import { PageView } from './PageView'
import {TemplateProjectsController} from "../../Controllers/PageControllers/TemplateProjectsController";
import {ProjectList} from "../Components/ProjectList";
import {controllers} from "../../Controllers/Controller";

class TemplateProjectsPage extends PageView {
    componentDidMount(){
        super.componentDidMount()
        controllers().menu.setIsBlured(true);
        controllers().menu.setIsBluredLock(true);
    }
    componentWillUnmount() {
        controllers().menu.setIsBluredLock(false);
    }
    get controller():TemplateProjectsController{
        return this.props.controller
    }

    render(){
        return (
            <div className={'p02'} style={{paddingTop: 100}}>
                <ProjectList url={'get_all_projects'} page={'project'} />
            </div>
        )
    }
}

export {TemplateProjectsPage }
