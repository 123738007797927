import { Lang } from "./Lang";

class French extends Lang {
    constructor() {
        super("fr", "French");
        this.vals = [
            {"": ""},
            {"A RELENTLESS PURSUIT OF EXCELLENCE": "UNE POURSUITE INCESSANTE DE L'EXCELLENCE"},
            {"LUXURY THROUGH CHOICE": "LE LUXE PAR LE CHOIX"},
            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them.": "Notre perspective unique permet des partenariats spéciaux. En collaboration avec chaque propriétaire, nous nous concentrons sur ce qui est important pour eux."},
            {"About us": "À propos de nous"},
            {"Cell phone number": "Numéro de téléphone portable"},
            {"Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future. Constant desire to improve and restore the best models of cars": "Chobal est une équipe de personnes dédiées à améliorer les réalisations du passé vers un avenir plus radieux. Désir constant d'améliorer et de restaurer les meilleurs modèles de voitures."},
            {"Classic & Sportscar Restorations": "Restauration de voitures classiques et de sport"},
            {"Connect": "Connecter"},
            {"Details makes perfect": "Les détails font la perfection"},
            {"EVERYTHING IS IMPORTANT": "TOUT EST IMPORTANT"},
            {"Enter cell phone number": "Entrez votre numéro de téléphone portable"},
            {"Enter your contact name": "Entrez votre nom de contact"},
            {"Enter your email address": "Entrez votre adresse e-mail"},
            {"Enter your phone number": "Entrez votre numéro de téléphone"},
            {"Home": "Accueil"},
            {"How we work": "Comment nous travaillons"},
            {"JOIN": "REJOINDRE"},
            {"Navigation": "Navigation"},
            {"Our restorations are exhibited at various European competitions, such as Classic Expo and others. We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "Nos restaurations sont exposées dans diverses compétitions européennes, telles que le Classic Expo et d'autres. Nous orientons nos ambitions vers la réalisation des rêves de nombreux amateurs de voitures rétro. Nos travaux sont évalués par des experts selon les normes allemandes les plus élevées et obtiennent des évaluations réussies."},
            {"Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems": "Notre équipe est spécialisée dans la restauration de voitures youngtimer et oldtimer, qui étaient des légendes de leur époque. Nous utilisons uniquement des matériaux et des détails de haute qualité pour la restauration. Vous pouvez être sûr que votre voiture sera dans un état parfait et parcourra des milliers de kilomètres sans problème."},
            {"Pieces of history": "Morceaux d'histoire"},
            {"Privacy": "Confidentialité"},
            {"Process of work": "Processus de travail"},
            {"SINCE 2006": "DEPUIS 2006"},
            {"Submit": "Soumettre"},
            {"Success in our mission requires a unique approach Every detail, no matter how small, must be considered and optimized. These three words are the constant mantra that guides our pursuit of perfection.": "Le succès de notre mission nécessite une approche unique. Chaque détail, aussi petit soit-il, doit être pris en compte et optimisé. Ces trois mots sont le mantra constant qui guide notre quête de perfection."},
            {"Target oriented": "Orienté vers l'objectif"},
            {"We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars. Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars, driven extensively by the most demanding collectors.": "Nous sommes un centre de restauration offrant des services complets, spécialisé dans les voitures classiques, avec un accent particulier sur les voitures de sport et de course. Notre personnel talentueux et dévoué est responsable de la restauration, de l'entretien et du soutien de nombreuses voitures parmi les plus belles au monde, conduites par les collectionneurs les plus exigeants."},
            {"Where we are": "Où nous sommes"},
            {"Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time. Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love": "Facteur wow ; la réponse que vous pouvez attendre - pour un service de restauration automobile primé ; transformer les détails en qualité d'exposition - un boulon à la fois. Chaque détail est important dans notre travail, nous faisons une restauration complète de chaque pièce. De l'écrou au moteur, nous nous assurons que chaque pièce est à sa place. Chaque détail de l'intérieur sera restauré avec amour."},
            {"support": "support"},
            {"Thank you for joining, we will anser soon.": "Merci de vous être inscrit, nous répondrons bientôt."},
            {"Classic car restoration details": "Détails de la restauration des voitures classiques"},
            {"Car restoration from 60th to 90th": "Restauration de voitures des années 60 à 90"},
            {"Request type": "Type de demande"},
            {"Complate": "Compléter"},
            {"Mechanics": "Mécanique"},
            {"Body": "Carrosserie"},
            {"Interior": "Intérieur"},
            {"Electrics": "Électricité"},
            {"Detailing": "Détaillage"},
            {"REVIEWS": "AVIS"},
            {"Coming Soon...": "Bientôt disponible..."},
            {"March 15": "15 mars"},
            {"April 10": "10 avril"},
            {"May 5": "5 mai"},
            {"June 20": "20 juin"},
            {"July 30": "30 juillet"},
            {"August 12": "12 août"},
            {"Languages": "Langues"},
            {"Contact info": "Informations de contact"},

            {"Projects": "Projets"},
            {"Blogs": "Blogs"}
        ];
    }
}

export { French };
