import { Lang } from "./Lang";

class Czech extends Lang {
    constructor() {
        super("cz", "Czech");
        this.vals = [
            {"": ""},
            {"A RELENTLESS PURSUIT OF EXCELLENCE": "NEUSTÁLÁ SNAHA O DOKONALOST"},
            {"LUXURY THROUGH CHOICE": "LUXUS DÍKY VOLBĚ"},
            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them.": "Náš jedinečný pohled umožňuje speciální partnerství. Ve spolupráci s každým majitelem se zaměřujeme na to, co je pro něj důležité."},
            {"About us": "O nás"},
            {"Cell phone number": "Telefonní číslo"},
            {"Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future. Constant desire to improve and restore the best models of cars": "Chobal je tým lidí věnujících se zlepšování dosažených úspěchů z minulosti do lepší budoucnosti. Neustálá snaha vylepšovat a obnovovat nejlepší modely aut."},
            {"Classic & Sportscar Restorations": "Restaurování klasických a sportovních vozů"},
            {"Connect": "Připojit se"},
            {"Details makes perfect": "Detaily tvoří dokonalost"},
            {"EVERYTHING IS IMPORTANT": "VŠE JE DŮLEŽITÉ"},
            {"Enter cell phone number": "Zadejte telefonní číslo"},
            {"Enter your contact name": "Zadejte své kontaktní jméno"},
            {"Enter your email address": "Zadejte svou e-mailovou adresu"},
            {"Enter your phone number": "Zadejte své telefonní číslo"},
            {"Home": "Domů"},
            {"How we work": "Jak pracujeme"},
            {"JOIN": "PŘIPOJIT SE"},
            {"Navigation": "Navigace"},
            {"Our restorations are exhibited at various European competitions, such as Classic Expo and others. We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "Naše restaurace jsou vystavovány na různých evropských soutěžích, jako je Classic Expo a další. Směřujeme své ambice k naplnění snů mnoha znalců retro aut. Naše práce jsou odborně hodnoceny kritiky německé kvality na nejvyšší úrovni a dosahují úspěšných hodnocení."},
            {"Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems": "Náš tým se zaměřuje na restaurování youngtimer a oldtimer aut, která byla legendami své doby. Při restaurování používáme pouze vysoce kvalitní detaily a materiály. Můžete si být jisti, že váš vůz bude ve skvělém stavu a bez problémů ujede tisíce kilometrů."},
            {"Pieces of history": "Kousky historie"},
            {"Privacy": "Soukromí"},
            {"Process of work": "Pracovní proces"},
            {"SINCE 2006": "OD ROKU 2006"},
            {"Submit": "Odeslat"},
            {"Success in our mission requires a unique approach Every detail, no matter how small, must be considered and optimized. These three words are the constant mantra that guides our pursuit of perfection.": "Úspěch v naší misi vyžaduje jedinečný přístup. Každý detail, bez ohledu na jeho velikost, musí být zohledněn a optimalizován. Tato tři slova jsou neustálou mantrou, která nás vede k dokonalosti."},
            {"Target oriented": "Cílené zaměření"},
            {"We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars. Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars, driven extensively by the most demanding collectors.": "Jsme restaurátorské zařízení poskytující kompletní služby se specializací na klasické vozy, s důrazem na sportovní a závodní vozy. Náš talentovaný a oddaný personál je odpovědný za restaurování, údržbu a podporu mnoha nejkrásnějších automobilů na světě, které jsou hojně využívány těmi nejnáročnějšími sběrateli."},
            {"Where we are": "Kde nás najdete"},
            {"Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time. Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love": "Wow efekt; odpověď, kterou můžete očekávat - za oceňovanou kvalitu služby restaurování aut; přeměna detailů na výstavní kvalitu - šroub po šroubu. Každý detail je v naší práci důležitý, provádíme kompletní restaurování každé části. Od matice až po motor dbáme na to, aby každá součást byla na svém místě. Každý detail interiéru bude obnoven s láskou."},
            {"support": "podpora"},
            {"Thank you for joining, we will anser soon.": "Děkujeme za připojení, brzy odpovíme."},
            {"Classic car restoration details": "Detaily restaurování klasických aut"},
            {"Car restoration from 60th to 90th": "Restaurování aut od 60. do 90. let"},
            {"Request type": "Typ požadavku"},
            {"Complate": "Dokončit"},
            {"Mechanics": "Mechanika"},
            {"Body": "Karoserie"},
            {"Interior": "Interiér"},
            {"Electrics": "Elektrika"},
            {"Detailing": "Detaily"},
            {"REVIEWS": "RECENZE"},
            {"Coming Soon...": "Již brzy..."},
            {"March 15": "15. března"},
            {"April 10": "10. dubna"},
            {"May 5": "5. května"},
            {"June 20": "20. června"},
            {"July 30": "30. července"},
            {"August 12": "12. srpna"},
            {"Languages": "Jazyky"},
            {"Contact info": "Kontaktní informace"},

            {"Projects": "Projekty"},
            {"Blogs": "Blogy"},
        ];
    }
}

export { Czech };
