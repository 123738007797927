import React from "react";
import {LangItem} from "../core/Helpers/LangItem";

export const ComingSoon: React.FC = () => (
    <div className="coming-soon-container">
        <div className="coming-soon-box">
            <div className="coming-soon-title">
                <LangItem for={"Coming Soon..."}/>
            </div>
        </div>
    </div>
);
