import { PageView } from './PageView';
import { DetailProjectsController } from "../../Controllers/PageControllers/DetailProjectsController";
import {HtmlRendererComponent} from "../Components/HtmlRendererComponent";
import {controllers} from "../../Controllers/Controller";


class DetailProjectPage extends PageView {
    componentDidMount() {
        super.componentDidMount();
        controllers().menu.setIsBlured(true);
        controllers().menu.setIsBluredLock(true);
    }
    componentWillUnmount() {
        controllers().menu.setIsBluredLock(false);
    }
    getProjectIdFromUrl(): string | null {
        const pathSegments = window.location.pathname.split('/');
        return pathSegments.length > 2 ? pathSegments[2] : null;
    }
    get controller(): DetailProjectsController {
        return this.props.controller;
    }

    render() {
        const id = this.getProjectIdFromUrl();
        if (!id) {
            return <></>
        }
        let url = `getproject/${id}`;
        return (
            <div>
                <HtmlRendererComponent url={url} paddingTop={0} />
            </div>
        );
    }
}

export { DetailProjectPage };
