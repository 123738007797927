import React from 'react';

type MapPhotoViewProps = {
    imageUrl: string; // URL картинки
    coordinates: {
        lat: number; // Широта
        lng: number; // Довгота
    };
    address?: string; // Адреса
};

const MapPhotoView: React.FC<MapPhotoViewProps> = ({ imageUrl, coordinates, address }) => {
    const handleClick = () => {
        const { lat, lng } = coordinates;
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(googleMapsUrl, '_blank');
    };

    return (
        <>
            {address && <div className="map-address">{address}</div>}
            <div className="map-coordinates">
                {coordinates.lat} {coordinates.lng}
            </div>
            <div className="map-photo-view" onClick={handleClick} style={{cursor: 'pointer'}}>
                <img src={imageUrl} alt="" className="map-photo"/>
            </div>
        </>

    );
};

export {MapPhotoView};
