import { Lang } from "./Lang";

class Italian extends Lang {
    constructor() {
        super("it", "Italian");
        this.vals = [
            {"": ""},
            {"A RELENTLESS PURSUIT OF EXCELLENCE": "UNA COSTANTE RICERCA DELL'ECCELLENZA"},
            {"LUXURY THROUGH CHOICE": "LUSSO ATTRAVERSO LA SCELTA"},
            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them.": "La nostra prospettiva unica consente partnership speciali. In collaborazione con ogni proprietario, ci concentriamo su ciò che è importante per loro."},
            {"About us": "Chi siamo"},
            {"Cell phone number": "Numero di cellulare"},
            {"Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future. Constant desire to improve and restore the best models of cars": "Chobal è un team di persone dedicate a migliorare i successi del passato per un futuro più luminoso. Desiderio costante di migliorare e restaurare i migliori modelli di auto."},
            {"Classic & Sportscar Restorations": "Restauro di auto classiche e sportive"},
            {"Connect": "Connetti"},
            {"Details makes perfect": "I dettagli fanno la perfezione"},
            {"EVERYTHING IS IMPORTANT": "TUTTO È IMPORTANTE"},
            {"Enter cell phone number": "Inserisci il numero di cellulare"},
            {"Enter your contact name": "Inserisci il nome del contatto"},
            {"Enter your email address": "Inserisci il tuo indirizzo email"},
            {"Enter your phone number": "Inserisci il tuo numero di telefono"},
            {"Home": "Home"},
            {"How we work": "Come lavoriamo"},
            {"JOIN": "UNISCITI"},
            {"Navigation": "Navigazione"},
            {"Our restorations are exhibited at various European competitions, such as Classic Expo and others. We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "I nostri restauri sono esposti in varie competizioni europee, come il Classic Expo e altre. Dirigiamo le nostre ambizioni per realizzare i sogni di molti appassionati di auto d'epoca. I nostri lavori sono valutati con competenza dai critici automobilistici secondo gli standard tedeschi più elevati e ricevono valutazioni di successo."},
            {"Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems": "Il nostro team è specializzato nel restauro di auto youngtimer e oldtimer, che erano leggende del loro tempo. Utilizziamo solo materiali e dettagli di alta qualità per il restauro. Puoi essere sicuro che la tua auto sarà in condizioni perfette e percorrerà migliaia di chilometri senza problemi."},
            {"Pieces of history": "Pezzi di storia"},
            {"Privacy": "Privacy"},
            {"Process of work": "Processo di lavoro"},
            {"SINCE 2006": "DAL 2006"},
            {"Submit": "Invia"},
            {"Success in our mission requires a unique approach Every detail, no matter how small, must be considered and optimized. These three words are the constant mantra that guides our pursuit of perfection.": "Il successo della nostra missione richiede un approccio unico. Ogni dettaglio, anche il più piccolo, deve essere considerato e ottimizzato. Queste tre parole sono il mantra costante che guida la nostra ricerca della perfezione."},
            {"Target oriented": "Orientato all'obiettivo"},
            {"We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars. Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars, driven extensively by the most demanding collectors.": "Siamo un centro di restauro a servizio completo specializzato in auto classiche, con un'enfasi su auto sportive e da corsa. Il nostro personale talentuoso e dedicato è responsabile del restauro, della manutenzione e del supporto di molte delle auto più belle del mondo, guidate ampiamente dai collezionisti più esigenti."},
            {"Where we are": "Dove siamo"},
            {"Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time. Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love": "Fattore wow; la risposta che puoi aspettarti - per un servizio di restauro auto premiato per qualità; trasformare i dettagli in qualità da esposizione - un bullone alla volta. Ogni dettaglio è importante nel nostro lavoro, eseguiamo un restauro completo di ogni parte. Dalla vite al motore, ci assicuriamo che ogni parte sia al suo posto. Ogni dettaglio degli interni sarà restaurato con amore."},
            {"support": "supporto"},
            {"Thank you for joining, we will anser soon.": "Grazie per esserti unito, risponderemo presto."},
            {"Classic car restoration details": "Dettagli del restauro di auto classiche"},
            {"Car restoration from 60th to 90th": "Restauro di auto dagli anni '60 agli anni '90"},
            {"Request type": "Tipo di richiesta"},
            {"Complate": "Completa"},
            {"Mechanics": "Meccanica"},
            {"Body": "Carrozzeria"},
            {"Interior": "Interni"},
            {"Electrics": "Elettricità"},
            {"Detailing": "Dettagli"},
            {"REVIEWS": "RECENSIONI"},
            {"Coming Soon...": "In arrivo..."},
            {"March 15": "15 marzo"},
            {"April 10": "10 aprile"},
            {"May 5": "5 maggio"},
            {"June 20": "20 giugno"},
            {"July 30": "30 luglio"},
            {"August 12": "12 agosto"},
            {"Languages": "Lingue"},
            {"Contact info": "Informazioni di contatto"},
            {"Projects": "Progetti"},
            {"Blogs": "Blog"}
        ];
    }
}

export { Italian };
