import { PageView } from './PageView'
import {BeforeAfterPhotosController} from "../../Controllers/PageControllers/BeforeAfterPhotosController";
import {ProjectList} from "../Components/ProjectList";
import {controllers} from "../../Controllers/Controller";

class BeforeAfterPhotosPage extends PageView {
    componentDidMount(){
        super.componentDidMount()
        controllers().menu.setIsBlured(true);
        controllers().menu.setIsBluredLock(true);
    }
    componentWillUnmount() {
        controllers().menu.setIsBluredLock(false);
    }
    get controller():BeforeAfterPhotosController{
        return this.props.controller
    }

    render(){
        return (
            <div className={'p02'}>
                <ProjectList url={'get_all_blogs'} page={'blog'}  paddingTop={100} />
            </div>
            )
    }
}

export { BeforeAfterPhotosPage }
