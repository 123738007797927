import { LangItem } from "../core/Helpers/LangItem";
import { WowText } from "../design/WowText";
import { PageView } from "./PageView";
import {ReviewList} from "../design/ReviewList";

class JoinedPage extends PageView {

    componentDidMount(): void {
        super.componentDidMount()
    }

    render() {
        return <div className="joined-page page">
            <WowText
                title='Our pleasure'
            >
                <LangItem for="Thank you for joining, we will anser soon."/>
            </WowText>
        </div>
    }

}

export { JoinedPage }
