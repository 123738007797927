import { Lang } from "./Lang";


class Deutsch extends Lang {

    constructor(){
        super("de", "German");
        this.vals = [
            // default entries
            {"": ""},

            {"A RELENTLESS PURSUIT OF EXCELLENCE": "EIN UNABHÄNGIGES STREBEN NACH EXZELLENZ"},

            {"LUXURY THROUGH CHOICE": "LUXUS DURCH WAHL"},

            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them.": "Unsere einzigartige Perspektive ermöglicht besondere Partnerschaften. In der Zusammenarbeit mit jedem Eigentümer konzentrieren wir uns darauf, was ihm wichtig ist."},

            {"About us": "Über uns"},

            {"Cell phone number": "Handynummer"},

            {"Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future. Constant desire to improve and restore the best models of cars": "Chobal ist ein Team von Menschen, die besonderes Ziel haben, die Errungenschaften der Vergangenheit in eine bessere Zukunft zu führen. Ständiger Wunsch, die besten Automodelle zu verbessern und zu restaurieren"},

            {"Classic & Sportscar Restorations": "Restaurierungen von Oldtimern und Sportwagen"},

            {"Connect": "Verbinden"},

            {"Details makes perfect": "Einzelheiten perfekt zu machen"},

            {"EVERYTHING IS IMPORTANT": "ALLES IST WICHTIG"},

            {"Enter cell phone number": "Handynummer eingeben"},

            {"Enter your contact name": "Geben Sie Ihren Kontaktnamen ein"},

            {"Enter your email address": "Geben Sie Ihre E-Mail-Adresse ein"},

            {"Enter your phone number": "Geben Sie Ihre Telefonnummer ein"},

            {"Home": "Die Hauptseite"},

            {"How we work": "Wie wir arbeiten"},

            {"JOIN": "BEITRETEN"},

            {"Navigation": "Navigation"},

            {"Our restorations are exhibited at various European competitions, such as Classic Expo and others. We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "Unsere Restaurierungen werden bei verschiedenen europäischen Wettbewerben wie der Classic Expo und anderen ausgestellt. Wir richten unsere Ambitionen und Engagement darauf, die Träume vieler Kenner von Retro-Autos zu verwirklichen. Unsere Werke werden von Maschinenkritikern deutscher Qualität auf höchstem Niveau von den Fachleuten bewertet und haben erfolgreiche Bewertungen"},

            {"Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems": "Unser Team konzentriert sich auf die Restaurierung von Youngtimern und Oldtimern, die zu ihrer Zeit Legenden waren. Nur hochwertige Details und Materialien verwenden wir für die Restaurierung. Sie können sicher sein, dass Ihr Auto in einwandfreiem Zustand bleibt und tausende Kilometer problemlos  zurücklegt"},

            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them": "Unsere einzigartige Perspektive ermöglicht besondere Partnerschaften. In Zusammenarbeit mit jedem Eigentümer konzentrieren wir uns auf das, was ihm wichtig ist"},

            {"Pieces of history": "Aus der Geschichte"},

            {"Privacy": "Datenschutz"},

            {"Process of work": "Arbeitsprozess"},

            {"SINCE 2006": "SEIT 2006"},

            {"Submit": "Absenden"},

            {"Success in our mission requires a unique approach Every detail, no matter how small, must be considered and optimized. These three words are the constant mantra that guides our pursuit of perfection.": "Der Erfolg unserer Mission erfordert einen einzigartigen Ansatz. Jedes kleine Detail muss berücksichtigt und optimiert werden. Diese drei Worte sind wichtig. Die Perfektion leitet unser Streben."},

            {"Target oriented": "Zielorientiert"},

            {"We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars. Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars, driven extensively by the most demanding collectors.": "Wir sind eine Vollservicerestaurierungswerkstatt, die sich auf klassische Autos spezialisiert , mit dem Schwerpunkt  Sport- und Rennwagen. Unsere talentierten, engagierten Mitarbeiter sind  für die Restaurierung verantwortlich. Wartung und Betreuung  der schönsten Autos der Welt, die von den anspruchsvollsten Sammlern gefahren werden."},

            {"Where we are": "Wo wir sind"},

            {"Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time. Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love.": "Die beste Reaktion, die Sie für einen preisgekrönten Qualitätsautorestaurierungsservice erwarten können, Umwandlung/ Transformation von Details, um Qualität zu zeigen .Eine Schraube sieht man nach der anderen. Jedes Detail ist wichtig dabei. Bei unserer Arbeit führen wir eine vollständige Restaurierung jedes Teils durch. Von der Mutter bis zum Motor stellen wir sicher, dass jedes Teil an seinem Platz ist. Jedes Detail des Interieurs wird liebevoll restauriert."},

            // {"support": "Unterstützung"/"Beratung"},

            {"Nice to meet you": "Schön Sie kennenzulernen"},

            {"Thank you for joining us. We will replied you as soon as posible.": "Danke, dass Sie sich uns angeschlossen haben. Wir werden Ihnen so schnell wie möglich antworten."},

            {"With plesure, your Chobal Restoration": "Mit freundlichen Grüßen, Ihr Chobal Team"},

            {"Thank you for joining, we will anser soon.": "Danke für Ihre Teilnahme, wir werden Ihnen bald antworten"},

            {"Classic car restoration details": "Details zur Restaurierung von Oldtimern"},
            {"Car restoration from 60th to 90th": "Autorestaurierung von 60 bis 90"},
            {"We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "Wir richten unsere Ambitionen und Engagement darauf, die Träume vieler Kenner von Retro-Autos zu verwirklichen. Unsere Werke werden von Maschinenkritikern deutscher Qualität auf höchstem Niveau von den Fachleuten bewertet und haben erfolgreiche Bewertungen"},

            {"Request type": "Anfragetyp"},
            {"Complate": "Komplett"},
            {"Mechanics": "Mechanik"},
            {"Body": "Körper"},
            {"Interior": "Innere"},
            {"Electrics": "Elektrik"},
            {"Detailing": "Detaillierung"},
            {"REVIEWS": "BEWERTUNGEN"},
            {"Coming Soon...": "Kommt bald..."},
            {"March 15": "15. März"},
            {"April 10": "10. April"},
            {"May 5": "5. Mai"},
            {"June 20": "20. Juni"},
            {"July 30": "30. Juli"},
            {"August 12": "12. August"},
            {"Languages": "Sprachen"},
            {"Contact info": "Kontaktinformationen"},

            {"Projects": "Projekte"},
            {"Blogs": "Blogs"}
        ]
    }
}

export { Deutsch }
