import { controllers } from '../../Controllers/Controller'
import { HomePageModel } from '../../Controllers/PageControllers/HomePageModel'
import { photosource } from '../../Helpers/Feathure'
import { MainCarousel } from '../design/MainCarousel'
import { WowCarousel } from '../design/WowCarousel'
import { WowDestiny } from '../design/WowDestiny'
import { WowText } from '../design/WowText'
import { PageView } from './PageView'
import {ReviewList} from "../design/ReviewList";

class HomePage extends PageView {
    componentDidMount(){
        super.componentDidMount()
    }

    get controller():HomePageModel{
        return this.props.controller
    }

    render(){
        return <div className="home page">
            <MainCarousel
                photos={[photosource("ppeVNG85v4Jo"), photosource("FZl6Zz1X6VQ0"), photosource("vEFW20MmUZvc"), photosource("gRGzQr70OPNY")]}
                title="SINCE 2006"
                description='Classic & Sportscar Restorations'
            />
            <WowText
                title='A RELENTLESS PURSUIT OF EXCELLENCE'
            >
                Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future.
                Constant desire to improve and restore the best models of cars
            </WowText>
            <WowCarousel photos={[photosource("XUw8TGggOSPk"), photosource("DFykil1N6ZIQ"), photosource("p4aKCirVN7Zm"),photosource("AVgrZtP5pgyA")]}/>
            <WowText
                title='EVERYTHING IS IMPORTANT'
            >
                Success in our mission requires a unique approach
                Every detail, no matter how small, must be considered and optimized.
                These three words are the constant mantra that guides our pursuit of perfection.
            </WowText>
            <WowCarousel photos={[photosource("S1wZSYXIrVFh"),photosource("7tQUSpLFaWry"),photosource("4Q1fUduuUMEZ"), photosource("qTy2Xl1vCyWq")]}/>
            <WowDestiny
                title="LUXURY THROUGH CHOICE"
                click={controllers().menu.rightToggle}
                photos={[photosource("l3uCMT9sIShN")]}
            >
                Our unique perspective enables special partnerships.
                In collaboration with each owner, we focus on what is important to them.
            </WowDestiny>
            <ReviewList />
        </div>
    }
}

export { HomePage }
