import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import { navigator } from '../Controllers/Navigator'
import { Core } from '../Views/core/Core'
import { controllers } from '../Controllers/Controller'
import { HomePage } from '../Views/pages/HomePage'
import { TargetOriented } from '../Views/pages/TargetOriented'
import { PiecesOfHistory } from '../Views/pages/PiecesOfHistory'
import { JoinedPage } from '../Views/pages/JoinedPage'
import {BeforeAfterPhotosPage} from "../Views/pages/BeforeAfterPhotosPage";
import {TemplateProjectsPage} from "../Views/pages/TemplateProjectsPage";
import {DetailProjectPage} from "../Views/pages/DetailProjectPage";
import {DetailBlogPage} from "../Views/pages/DetailBlogPage";

class MainNavigation extends React.Component {

    render(){
        const {home, targetOriented, piecesOfHistory, beforeAfterPhotos, templateProjects,  joined, details, blog} = controllers().page
        const { currentPage } = navigator()
        // home, favorites, series,
        return <Router
            ref={(ref:any)=>{
                navigator().initNavigation(ref)
            }}
        >
            <Core>
                {currentPage === "/processing" ? <TargetOriented controller={targetOriented}/>
                : currentPage === "/piecesofhistory" ? <PiecesOfHistory controller={piecesOfHistory}/>
                : currentPage === "/projects" ? <TemplateProjectsPage controller={templateProjects}/>
                : currentPage === "/blogs" ? <BeforeAfterPhotosPage controller={beforeAfterPhotos}/>
                : currentPage === "/joined" ? <JoinedPage controller={joined}/>
                : currentPage === "/project" ? <DetailProjectPage controller={details}/>
                : currentPage === "/blog" ? <DetailBlogPage controller={blog} />
                : <HomePage controller={home}/>}
            </Core>
        </Router>
    }
}

export { MainNavigation }
