import React from 'react';
import './assets/styles/App.css';
import './assets/styles/design.css';
import './assets/fonts/mainfonts.css';
import './assets/styles/footer.css';
import './assets/styles/darkmode.css';
import './assets/styles/reviews.css';
import './assets/styles/htmlRender.css';

import {Provider} from 'react-redux';
import store from './redux';
import { AppStateController } from './Core/AppStateController';
import { Helpers } from './Core/Helpers';
import { MainNavigation } from './Navigation/MainNavigation';
import { controllers } from './Controllers/Controller';
import { navigator } from './Controllers/Navigator';

function App() {
  return <div className="App" id="APP_CONTROLLER">
          <div className={`App-box ${controllers().media.mode}-mode`} id="mainscroll" ref={controllers().scroll.set}>
              <Provider store={store}>
                <AppStateController/>
                <Helpers/>
                <MainNavigation ref={navigator().setNowRef}/>
              </Provider>
          </div>
        </div>
}

export default App;
